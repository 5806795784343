<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">

                    <div><router-link to="/Admin/tgjingmtxlb/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <!--<div><el-input v-model="where.phone" placeholder="请输入用户手机号"></el-input></div>-->
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <div style="width:130px;">
                        <el-select v-model="where.F_IN_FENLID" placeholder="请选择分类" @change="selectChange()">
                            <el-option label="请选择分类" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['fenl']" :label="v.F_VR_MINGC" :key="k"
                                       :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button></div>
                </div>
                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <!--<div class="admin_main_block_top">
                <div class="admin_main_block_right" style="width:600px;">
                    <div>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                    </div>
                </div>
            </div>-->


            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="F_IN_ID" label="#" fixed="left" width="70px"></el-table-column>
                    <el-table-column prop="F_VR_MINGC" label="分类名称"  ></el-table-column>
                    <el-table-column label="图片" >
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.F_VR_TUP||require('@/assets/default_pic.png')" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_TUP||require('@/assets/default_pic.png')"
                                        :alt="scope.row.F_VR_TUP"
                                        style="max-height: 60px;max-width: 60px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>


                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'tgjingmtxlb_form',params:{F_IN_ID:scope.row.F_IN_ID}})">编辑</el-button>
                            <!--<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>-->
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total"
                                   :total="total_data" :page-size="page_size"
                                   :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list: [],
                total_data: 0, // 总条数
                page_size: 20,
                current_page: 1,
                select_id: '',
                dialogVisible: false,
                info: [],
                store_verify: 0,
                where: {
                    times: [],
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            handleSelectionChange: function (e) {
                let ids = [];
                e.forEach(v => {
                    ids.push(v.F_IN_ID);
                });
                this.select_id = ids.join(',');
            },
            get_store_list: function () {
                let where = {};
                where.page = this.current_page;
                where.params = this.where;
                this.$get(this.$api.getTgJingmtxlbList, where).then(res => {
                    this.page_size = res.data.info.per_page;
                    this.total_data = res.data.info.total;
                    this.current_page = res.data.info.current_page;
                    this.list = res.data;
                    this.info = res.data.info.data;


                })
            },
            read_store_info(e) {
                this.info = e;
                this.store_verify = this.info.store_verify;
                this.dialogVisible = true;
            },
            read_pic(e) {
                return [e];
            },
            update_store: function () {
                if (this.info.store_status == 0 && this.info.store_close_info == '') {
                    return this.$message.error('请填写关闭店铺的原因！');
                }
                this.info.store_verify = this.store_verify;
                this.$post(this.$api.StorePass, this.info).then(res => {
                    this.$message.success('修改成功');
                    this.dialogVisible = false;
                    this.get_store_list();
                });
            },
            // 分页改变
            current_change: function (e) {
                this.current_page = e;
                this.get_store_list();
            },

            // 批量热门推荐
            pilrem_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过推荐, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlplbremStatus, {F_IN_ID: F_IN_ID, status: 1}).then(() => {
                        this.$message.success('成功推荐');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlplbremStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },
            // 批量官方推荐
            pilguanf_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过推荐, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplguanfStatus, {F_IN_ID: F_IN_ID, status: 1}).then(() => {
                        this.$message.success('成功推荐');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplguanfStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },
            // 批量上下架
            pilshangxj_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过上下架, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplshangxjStatus, {F_IN_ID: F_IN_ID,status: 1}).then(() => {
                        this.$message.success('上架成功');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplshangxjStatus, {F_IN_ID: F_IN_ID, status: 0}).then(() => {
                        this.$message.info('下架成功');
                    });

                });
            },
            // 批量审核
            pilshenh_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbplVerifyChange, {F_IN_ID: F_IN_ID, status: 2}).then(() => {
                        this.$message.success('成功审核');
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbplVerifyChange, {F_IN_ID: F_IN_ID,status:3}).then(() => {
                        this.$message.info('已拒绝');
                    });

                });
            },


            // 删除处理
            del: function (F_IN_ID) {
                let _this = this;
                if (this.$isEmpty(F_IN_ID)) {
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delTgJingmtxlb, {F_IN_ID: F_IN_ID}).then(function (res) {
                    if (res.code == 200) {
                        _this.get_store_list();
                        return _this.$message.success("删除成功");
                    } else {
                        return _this.$message.error("删除失败！" + res.msg);
                    }
                });
            },
            // 指定ID修改状态
            rem_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbremStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            // 指定ID修改状态
            guanf_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbguanfStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            // 指定ID修改状态
            shangxj_status: function (F_IN_ID) {
                this.$post(this.$api.tgbizlbshangxjStatus, {F_IN_ID: F_IN_ID}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                    } else {
                        this.$message.success('操作失败');
                    }
                    this.get_store_list();
                });
            },
            verify_click: function (F_IN_ID) {
                this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.tgbizlbVerifyChange, {F_IN_ID: F_IN_ID, F_TI_SHENHZT: 2}).then(() => {
                        this.$message.success('成功审核');
                        this.get_store_list();
                    });

                }).catch(() => {
                    this.$post(this.$api.tgbizlbVerifyChange, {F_IN_ID: F_IN_ID, F_TI_SHENHZT: 3}).then(() => {
                        this.$message.info('已拒绝');
                        this.get_store_list();
                    });

                });
            },
        }
        ,
        created() {
            this.get_store_list();
        }
        ,
        mounted() {
        }
    }
    ;
</script>
<style lang="scss" scoped>
    .store_list_ul li {
        border-bottom: 1px solid #efefef;
        margin: 0 0 30px 0;
        padding-bottom: 15px;
        color: #666;

        h4 {
            margin-bottom: 15px;
            color: #333;
        }
    }
</style>